import { ILocalizationLanguage } from '~/store/localization/types/LocalizationTypes'

export const actions = {
  setLanguage(language: ILocalizationLanguage) {
    // @ts-ignore
    this.selectedLanguage = language
  },
  resetState() {
    // Reset state to initial values
    // @ts-ignore
    this.$reset()
  },
}
