// packages
import { defineStore } from 'pinia'
import { actions } from '~/store/localization/actions'
import { getters } from '~/store/localization/getters'
import { LOCALIZATION_STORE } from '~/constants/stores/stores'
import type ILocalizationState from '~/store/localization/interfaces/ILocalizationState'
import { type ILocalizationLanguage } from '~/store/localization/types/LocalizationTypes'
import localization from '~/constants/localization/localization'
import Languages from '~/constants/constants/languages'

const state = (): ILocalizationState => ({
  selectedLanguage: <ILocalizationLanguage>{
    literal: Languages.en,
    title: localization.english,
  },
  languages: <ILocalizationLanguage[]>[
    {
      literal: Languages.en,
      title: localization.english,
      emoji: '🇺🇸',
    },
    {
      literal: Languages.fr,
      title: localization.french,
      emoji: '🇫🇷',
    },
    {
      literal: Languages.es,
      title: localization.spanish,
      emoji: '🇪🇸',
    },
  ],
})

export const useLocalizationStore = defineStore(LOCALIZATION_STORE, {
  state,
  actions,
  getters,
  persist: {
    storage: persistedState.localStorage,
  },
})
